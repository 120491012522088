import React from 'react';

export const LogoWhite = ({ className }) => {
  return (
    <svg
      className={className ? className : ''}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 98.66 72.39'
    >
      <title>Asset 6</title>
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Layer_1-2' data-name='Layer 1'>
          <path
            fill='#fff'
            d='M79.14,67.48H70.86V37.29h8.39c7.48,0,13.64,5.08,13.64,15.18s-6.27,15-13.75,15m.28-35.1H65.27V47.25H41.84c.52-2.26,2.4-3.78,4.51-5.13l7.3-4.63c4.23-2.65,7-6.49,7-11.29,0-6.09-4.57-11.23-12.5-11.23-4.74,0-8.3,1.91-10.45,4.75h0c-.39.56-.79,1.25-1.23,2-.84,1.54-1.8,3.44-2.95,5.45-.72,1.26-1.51,2.56-2.39,3.84l0,0h0a12.65,12.65,0,0,1-11,5.55c-7.36,0-14.38-5.36-14.38-15.8s7-15.74,14.38-15.74c5.38,0,9.33,2.24,11.44,6.32l4.72-2.79C33.27,3.26,27.57,0,20.09,0,10,0,0,7.45,0,20.82S9.64,41.7,20.09,41.7c7,0,12.27-3,15.43-7.64v0h0a61.17,61.17,0,0,0,3.1-5.36c1.11-2.06,2.27-4.2,3.39-5.85l.08-.12h0l0-.09.21-.3A6.7,6.7,0,0,1,48,19.83c4.68,0,7,3.1,7,6.43,0,2.82-1.6,5.42-5.08,7.62l-6.16,3.95c-6.28,4-8.68,8.57-8.79,14.55H65.27v20h14c10.27,0,19.35-6.83,19.35-19.92s-9-20.09-19.24-20.09'
          />
        </g>
      </g>
    </svg>
  );
};
