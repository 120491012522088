import { makeStyles } from "@material-ui/core/styles";
import { theme as localTheme } from "../../styles";

export const topHeaderUseStyles = makeStyles((theme) => ({
  container: {
    marginRight: "50px",
    marginLeft: "200px",
    marginTop: "5px",
    width: "100%",
    maxWidth: "600px",
    [theme.breakpoints.up("md")]: {
      maxWidth: localTheme.scale("500px"),
      marginLeft: localTheme.scale("150px"),
    },
    [theme.breakpoints.up("l")]: {
      maxWidth: localTheme.scale("600px"),
      marginLeft: localTheme.scale("200px"),
    },
  },
  item: {
    textDecoration: "none!important",
    color: "black",
  },
  link: {
    fontFamily: localTheme.fontFamily.secondary,
    fontSize: localTheme.fonts.m,
    textTransform: "uppercase",
    cursor: "pointer",
    margin: "0",
    marginBottom: "4px",
    padding: "0px 4px !important",
    display: "inline",
    fontWeight: "500",
    border: "1px solid white",
    "&:active": {
      border: "1px solid red",
      color: "red",
    },
    "&$selected": {
      border: "1px solid red",
      color: "red",
    },
    "&.active": {
      border: "1px solid red",
      color: "red",
    },
    [theme.breakpoints.up("md")]: {
      "&:hover": {
        border: "1px solid red",
        color: "red",
      },
      fontSize: localTheme.scale(localTheme.fonts.m),
    },

    letterSpacing: "0.05em",
  },
}));

export const flyoutMenuUseStyles = makeStyles((theme) => ({
  sidebar: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "500px",
  },
  nav__link_decoration: {
    display: "flex",
    alignItems: "center",
  },
  link: {
    textTransform: "uppercase",
    fontSize: "37px",
    color: "white",
    paddingBottom: "0",
    fontFamily: localTheme.fontFamily.secondary,
    lineHeight: "0px",
    letterSpacing: "0px",
    fontWeight: "500",
    border: `1px solid ${localTheme.colors.primaryred}`,
    textDecoration: "none",
    paddingLeft: "0px",
    "& a": {
      color: "white",
      "&:focus": {
        outline: "none !important",
        border: "1px solid white",
      },
      "&:active": {
        outline: "none !important",
        border: "1px solid white",
        padding: "opx 3px 0px",
      },
    },
    [theme.breakpoints.up("600")]: {
      fontSize: "50px",
      lineHeight: "45px",
      letterSpacing: "0.04em",
    },
  },
}));
