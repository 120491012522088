import React, { createContext, useState } from 'react';

const ProjectsContext = createContext();

export const ProjectsProvider = ({ children }) => {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedService, setSelectedService] = useState('');
  const [selectedExpertise, setSelectedExpertise] = useState('');

  return (
    <ProjectsContext.Provider
      value={{
        selectedCountry,
        setSelectedCountry,
        selectedService,
        setSelectedService,
        selectedExpertise,
        setSelectedExpertise,
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};

export default ProjectsContext;
