/* eslint no-unused-expressions: 0 */
/* eslint react/destructuring-assignment: 0 */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Wrapper from '../components/Wrapper';

const Layout = ({ children }) => {
  const scrollRef = useRef(null);

  return <Wrapper ref={scrollRef}>{children}</Wrapper>;
};

export { Layout };

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
};
