const theme = {
  colors: {
    primaryred: "#f54634",
    primaryredrgba: "245, 70, 52",
    primaryblue: "#324CCB",
    bg: "#ffffff",
    black: "#000",
    greyLight: "#EBEDF2",
    greyBlue: "#a2bce2",
    grey: "#595C62",
    greyDark: "#303643",
    greyDarker: "#1c252b",
  },
  maxWidthDesktop: "1440px",
  maxWidthMobile: "600px",
  maxWidthText: "720px",
  breakpoints: {
    xxs: "350px",
    xs: "400px",
    s: "600px",
    md: "960px",
    m: "1000px",
    l: "1200px",
    lg: "1280px",
    xl: "1920px",
  },
  spacing: {
    desktop: {
      primary: "300px",
    },
    mobile: {
      primary: "150px",
    },
  },
  headerHeight: {
    desktop: "260px",
    mobile: "140px",
  },
  fontFamily: {
    primary: "Helvetica",
    secondary: "Roboto Mono",
    tertiary: "Roboto",
  },
  fonts: {
    xxs: "8px",
    xs: "12px",
    s: "14px",
    m_small: "16px",
    m: "18px",
    md: "20px",
    l: "24px",
    xl_small: "26px",
    xl_medium: "30px",
    xxl_small: "40px",
    xxl_medium: "50px",
    xxl_large: "68px",
    xxxl_small: "84px",
    xxxl: "90px",
    xxxxl: "128px",
  },
  graphicsColors: {
    lightGreen: "#B6FFEB",
    darkGreen: "#007945",
    lavanderPurple: "#DADFF6",
    darkBlue: "#324CCB",
    lightRose: "#FAE4EF",
    red: "#F54634",
    lightOrange: "#FFEFE5",
    darkOrange: "#E49835",
  },
  scale: (value, by = 0.1) => {
    by = 1 - by;

    if (value.indexOf(" ") >= 0) {
      value = value.split(" ");
      value = value
        .map((v) => `${Math.floor(+v.split("px")[0] * by)}px`)
        .join(" ");
      return value;
    } else {
      value = value.split("px")[0];
      value = value * by;
      return `${Math.floor(value)}px`;
    }
  },
};

export default theme;
