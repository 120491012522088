import styled from '@emotion/styled';
import { theme } from '../../styles';

import { Marker } from 'react-simple-maps';

export const MarkerStyled = styled(Marker)`
  opacity: ${(props) => (props.aMarkerIsHovered ? 0.2 : 1)};
  transition: opacity 400ms ease;
`;

export const MapFiltersContainer = styled.div`
  color: ${theme.colors.primaryred};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -30px;

  @media (min-width: ${theme.breakpoints.md}) {
    margin-top: -140px;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
  }

  .MuiInput-underline {
    &::before {
      display: none;
    }
  }
`;

export const MapFiltersHeader = styled.div`
  font-weight: 600;
  letter-spacing: 0.04em;
  border-bottom: 2px solid white;
  display: block;
  padding: 10px;
  width: 100%;

  @media (min-width: ${theme.breakpoints.md}) {
    margin-right: 30px;
    width: auto;
    border-bottom: 0;
  }
`;

export const MapFiltersBody = styled.div``;
