import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { SocialMediaContainer } from "./SocialMedia.css";
import LocaleContext from '../../context/LocaleProvider';
import Text from "../Text/Text";

const SocialMedia = ({ customClassName }) => {
  const data = useStaticQuery(graphql`
    query SocialMediaQuery {
      socialMedia: allPrismicSocialMedia {
        edges {
          node {
            lang
            data {
              social_media {
                social_media_label {
                  text
                }
                social_media_link {
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  const socialMediaProps = {
    className: customClassName ? customClassName : "",
  };

  const lang = React.useContext(LocaleContext);
  const i18n = lang.i18n[lang.locale];

  const socialMediaData = data.socialMedia.edges
    .filter((edge) => edge.node.lang === i18n.locale)
    .map((r) => r.node.data);

  const socialMediaQuery = socialMediaData[0];

  return (
    <SocialMediaContainer {...socialMediaProps}>
      {socialMediaQuery.social_media
        ? socialMediaQuery.social_media.map((social, index) => {
            return (
              <div
                className="SocialMedia__Content-Container"
                key={`SocialMedia - ${index}`}
              >
                <a href={`${social.social_media_link.url}`} target="_blank" rel="noopener">
                  <Text customClassName="SocialMedia__Content-Text">
                    {social.social_media_label.text}
                  </Text>
                </a>
                <Text
                  customClassName="SocialMedia_Content-Symbol SocialMedia__Content-Line"
                  customStyle={{ marginRight: "15px" }}
                >
                  {index === socialMediaQuery.social_media.length - 1
                    ? ""
                    : "—"}
                </Text>
              </div>
            );
          })
        : ""}
    </SocialMediaContainer>
  );
};

export default SocialMedia;
