import React from 'react';
import PropTypes from 'prop-types';
import { TextStyled } from './Text.css';

const Text = ({
  children,
  customClassName,
  customStyle,
  type,
  as = 'p',
  dangerouslySetInnerHTML,
}) => {
  const textProps = {
    as,
    style: customStyle ? customStyle : null,
    className: customClassName ? customClassName : null,
    type,
  };

  return dangerouslySetInnerHTML ? (
    <TextStyled
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      {...textProps}
    />
  ) : (
    <TextStyled {...textProps}>{children}</TextStyled>
  );
};

Text.propTypes = {
  customClassName: PropTypes.string,
  customStyle: PropTypes.object,
  type: PropTypes.string,
  as: PropTypes.string,
};

export default Text;
