import styled from '@emotion/styled';
import { theme } from '../../styles';

export const TextStyled = styled.p`
  font-family: ${theme.fontFamily.primary};
  font-weight: 100;
  line-height: 1.3;
  letter-spacing: 0px;

  ${({ type }) => {
    switch (type) {
      case 'primary':
        //
        // Primary Font Style
        //
        return `
        font-size: ${theme.fonts.l};
        `;
      case 'secondary':
        //
        // Secondary Font Style
        //
        return `
          font-size: ${theme.fonts.m};
          `;
      case 'tertiary':
        //
        // Tertiary Font Style
        //
        return `
            font-size: ${theme.fonts.m_small};
          `;
      case 'teriary-xl':
        //
        //
        //
        return `
        font-size: ${theme.fonts.m};
          `;
      case 'quaternary':
        //
        //
        //
        return `
            font-size: ${theme.fonts.m};
            text-transform: uppercase;
            font-family: ${theme.fontFamily.secondary};
            letter-spacing: 0.2px;
          `;
      default:
        //
        // Default Font Style
        //
        return `
            font-size: ${theme.fonts.s};
        `;
    }
  }};

  @media (min-width: ${theme.breakpoints.md}) {
    ${({ type }) => {
      switch (type) {
        case 'primary':
          //
          // Primary Font Style
          //
          return `
            font-size: ${theme.scale(theme.fonts.xl_medium)};
          `;
        case 'secondary':
          //
          // Secondary Font Style
          //
          return `
            font-size: ${theme.scale(theme.fonts.xl_small, 0.12)}; 
          `;
        case 'tertiary':
          //
          // Tertiary Font Style
          //
          return `
            font-size: ${theme.scale(theme.fonts.m)}; 
          `;
        case 'teriary-xl':
          //
          //
          //
          return `
        font-size: ${theme.scale(theme.fonts.xl_medium)};
          `;
        case 'quaternary':
          //
          //
          //
          return `
                font-size: ${theme.scale(theme.fonts.m)};
                text-transform: uppercase;
                font-family: ${theme.fontFamily.secondary};
                letter-spacing: 0.2px;
              `;
        default:
          //
          // Default Font Style
          //
          return `
            font-size: ${theme.scale(theme.fonts.m)}; 
          `;
      }
    }};
  }
`;
