// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-en-articles-js": () => import("./../../../src/pages/en.articles.js" /* webpackChunkName: "component---src-pages-en-articles-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en.contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en.index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-projects-js": () => import("./../../../src/pages/en.projects.js" /* webpackChunkName: "component---src-pages-en-projects-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-generic-js": () => import("./../../../src/templates/generic.js" /* webpackChunkName: "component---src-templates-generic-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-solution-js": () => import("./../../../src/templates/solution.js" /* webpackChunkName: "component---src-templates-solution-js" */),
  "component---src-templates-team-member-js": () => import("./../../../src/templates/team-member.js" /* webpackChunkName: "component---src-templates-team-member-js" */)
}

