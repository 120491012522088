import { css } from '@emotion/core';
import theme from './theme';
import reset from './reset';
import { keyframes } from '@emotion/core';
import { MarkerStyled } from '../components/WorldMap/MapFilters.css';

const logoReveal = keyframes`
0% {
  stroke-dashoffset: 944.3px; animation-timing-function: cubic-bezier(.4,0,1,1);
}

100% {
  stroke-dashoffset: 0px; animation-timing-function: cubic-bezier(0,0,.6,1);
}
`;

const globalStyle = css`
  ${reset}
  h1, h2, h3, h4, h5, h6 {
    color: ${theme.colors.black};
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    color: ${theme.colors.black};
    background-color: ${theme.colors.bg};

    &.is-red {
      background-color: ${theme.colors.primaryred};

      .Header__PlusSVG .cls-4 {
        fill: black;
      }

      .HeaderContainer {
        background-color: ${theme.colors.primaryred};

        .MuiGrid-item {
          a {
            border-color: ${theme.colors.primaryred};

            &.active,
            &:hover {
              border-color: white;
              color: white;
            }
          }
        }

        .LocaleSwitcher {
          border-color: ${theme.colors.primaryred};

          &:hover {
            border-color: white;
          }

          .Header__LanguageSwitcher-button {
            color: white;
          }
        }
      }

      .Footer__Report-container {
        a {
          background: white;
          color: ${theme.colors.primaryred};
        }
      }
    }
  }
  ::selection {
    color: ${theme.colors.bg};
    background-color: ${theme.colors.primaryred};
  }
  a {
    color: ${theme.colors.black};
    transition: all 0.4s ease-in-out;
    text-decoration: none;
    font-weight: 700;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .CountryCircle {
    cursor: pointer;


    circle {
      transition: r 300ms ease-out;
      will-change: r;
    }

    .MarkerText {
      opacity: 0;
      transform: translateY(-15px);
      will-change: opacity, transform;
      pointer-events: none;
    }

    &:hover & {
      opacity: 0.2;
    }

    &:hover {
      /* opacity: 1; */

      circle {
        @media (min-width: ${theme.breakpoints.s}) {
          r: 20 !important;
        }
      }



      .MarkerText {
        opacity: 1;
        transform: translateY(-25px);
        transition: 300ms ease-out;
        transition-property: opacity, transform;
      }
    }
  }

  .ml11 .text-wrapper {
    position: relative;
    display: inline-block;
  }

  .ml11 .line {
    opacity: 0;
    position: absolute;
    left: 0;
    height: 100%;
    width: 3px;
    background-color: black;
    transform-origin: 0 50%;
  }

  .ml11 .line1 {
    top: 0;
    left: 0;
  }

  .ml11 .letter {
    display: inline-block;
  }

  .PlusIcon {
    transition: transform 600ms ease;
    will-change: transform;

    &:hover {
      transform: rotate(180deg) scale(0.85);
    }
  }

  .LogoPath {
    animation: 1.4s linear forwards ${logoReveal};
  }

  .HeadingContainer__TitleBody {

    h3 {
      line-height: 1.3;
    }

    li {
      padding-left: 10px;
      margin-bottom: 15px;
      position: relative;
      font-weight: 400;

      /* &::before {
        content: '';
        position: absolute;
        top: 2px;
        left: -20px;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        background: ${theme.colors.primaryred};
      } */
    }

    /* ul {
      list-style: none;
    } */

    a {
      text-decoration: underline;
    }
  }
`;

export default globalStyle;
