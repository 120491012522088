import React from 'react';
import { LocaleSwitcher } from './Header.css';
import { Link } from 'gatsby';
import LocaleContext from '../../context/LocaleProvider';

const LocaleComponent = ({ location }) => {
  const lang = React.useContext(LocaleContext);
  const i18n = lang.i18n[lang.locale];

  const enPath = `${
    location.pathname.includes('/fr')
      ? location.pathname.replace('/fr', '')
      : location.pathname
    }`;
  
  const frPath = `/fr${location.pathname}`;

  return (
    <>
      {i18n.locale === 'en-gb' ? (
        <Link className='Header__LanguageSwitcher-button' to={frPath}>
          FR
        </Link>
      ) : i18n.locale === 'fr-ca' ? (
        <Link to={enPath} className='Header__LanguageSwitcher-button'>
          EN
        </Link>
      ) : (
        ''
      )}
    </>
  );
};

export default LocaleComponent;
