import React from 'react';

export const EscBtn = ({ className }) => {
  return (
    <svg
      className={className ? className : ''}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 37.49 37.49'
    >
      <title>Asset 5</title>
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Layer_1-2' data-name='Layer 1'>
          <polygon
            fill='#fff'
            points='34.06 0 18.75 15.31 3.44 0 0 3.44 15.31 18.75 0 34.06 3.44 37.49 18.75 22.18 34.06 37.49 37.49 34.06 22.18 18.75 37.49 3.44 34.06 0'
          />
        </g>
      </g>
    </svg>
  );
};
