import React, { createContext, useEffect } from 'react';
import i18n from '../../config/i18n';
import { navigate } from 'gatsby';
import { useSessionStorage } from 'react-use';

const LocaleContext = createContext();

export const LocaleProvider = ({ children, locale, location }) => {
  const [firstEntryLocale, setFirstEntryLocale] = useSessionStorage(
    'first-entry-locale'
  );
  useEffect(() => {
    if (!firstEntryLocale) {
      const browserLocale = navigator.language.toLowerCase();
      const englishRoute = location.pathname.includes('/fr')
        ? location.pathname.replace('/fr', '')
        : location.pathname;
      const frenchRoute = `/fr${location.pathname}`;

      if (browserLocale.includes('fr')) {
        navigate(frenchRoute);
        setFirstEntryLocale('fr');
      } else {
        setFirstEntryLocale('en');
        navigate(englishRoute);
      }
    }
  }, []);

  return (
    <LocaleContext.Provider value={{ locale, i18n }}>
      {children}
    </LocaleContext.Provider>
  );
};

export default LocaleContext;
