import React from 'react';

export const HeaderImageMobile = ({ className }) => {
  return (
    <svg
      className={className ? className : ''}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 162.37 48.16'
    >
      <title>Asset 3</title>
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Layer_1-2' data-name='Layer 1'>
          <rect x='157.51' width='4.86' height='48.16' />
          <rect x='132.76' width='4.86' height='48.16' />
          <rect x='108.74' width='4.13' height='48.16' />
          <rect x='85.95' width='2.91' height='48.16' />
          <rect x='63.83' width='2.23' height='48.16' />
          <rect x='41.71' width='2.23' height='48.16' />
          <rect x='20.86' width='0.97' height='48.16' />
          <rect width='0.97' height='48.16' />
        </g>
      </g>
    </svg>
  );
};
