import React from 'react';

export const HeaderImageDesktop = ({ className }) => {
  return (
    <svg
      className={className ? className : ''}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 270.61 80.27'
    >
      <title>Image</title>
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Layer_1-2' data-name='Layer 1'>
          <rect x='262.52' width='8.1' height='80.27' />
          <rect x='221.27' width='8.1' height='80.27' />
          <rect x='181.24' width='6.89' height='80.27' />
          <rect x='143.24' width='4.85' height='80.27' />
          <rect x='106.38' width='3.71' height='80.27' />
          <rect x='69.52' width='3.71' height='80.27' />
          <rect x='34.76' width='1.61' height='80.27' />
          <rect width='1.61' height='80.27' />
        </g>
      </g>
    </svg>
  );
};
