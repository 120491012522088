import React, { useState, useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HeaderContainer } from './Header.css';
import LocaleContext from '../../context/LocaleProvider';
import TopHeader from './TopHeader';
import FlyoutMenu from './FlyoutMenu';

const HeaderMain = ({ location }) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      header: allPrismicHeader {
        edges {
          node {
            lang
            data {
              header_links {
                link_title {
                  text
                }
                link {
                  uid
                }
              }
            }
          }
        }
      }
      footerData: allPrismicFooter {
        edges {
          node {
            lang
            data {
              logo {
                url
              }
              contact_email {
                text
              }
              mail_to {
                text
              }
              contact_tel {
                text
              }
              contact_address {
                text
              }
              pdf_label {
                text
              }
              pdf_link {
                url
              }
            }
          }
        }
      }
    }
  `);

  const lang = useContext(LocaleContext);
  const i18n = lang.i18n[lang.locale];
  const header = data.header.edges
    .filter((edge) => edge.node.lang === i18n.locale)
    .map((r) => r.node.data);

  const footerQuery = data.footerData.edges
    .filter((edge) => edge.node.lang === i18n.locale)
    .map((r) => r.node.data);

  const menuItemsQuery = header[0].header_links;

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  return (
    <HeaderContainer className='HeaderContainer'>
      {menuItemsQuery ? (
        <TopHeader
          menuItems={menuItemsQuery}
          onSidebarOpen={handleSidebarOpen}
          location={location}
        />
      ) : (
        ''
      )}
      <FlyoutMenu
        open={openSidebar}
        onOpen={handleSidebarOpen}
        onClose={handleSidebarClose}
        menuItems={menuItemsQuery}
        infoQuery={footerQuery}
        location={location}
      />
    </HeaderContainer>
  );
};

export default HeaderMain;
