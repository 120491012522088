import React from 'react';
import { LocaleSwitcher } from './Header.css';
import { HeaderImageMobile } from '../Icons/HeaderImageMobile';
import { HeaderImageDesktop } from '../Icons/HeaderImageDesktop';
import { PlusRed } from '../Icons/PlusRed';
import { Desktop, Mobile } from '../Media/Media';
import { Grid } from '@material-ui/core';
import LocalizedLink from '../LocalizedLink';
import LocaleComponent from './LocaleComponent';
import { topHeaderUseStyles } from './HeaderMaterialStyles.css';
import { HeaderMobileContainer, HeaderDesktopContainer } from './Header.css';
import { useWindowScroll } from 'react-use';

const LogoSvg = () => (
  <LocalizedLink to='/'>
    <svg
      className='Header__Logo'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 310.5 230.4845'
    >
      <path
        className='LogoPath'
        d='M106.9 32.3c-19.8-36.7-98.4-35.3-98.4 35.3c0 37.2 25.3 54.8 51.7 56.7c21.9 1.5 42.7-8.8 53.6-37.5c8.3-21.7 21.4-32.1 37.2-31.3c15.8 .9 31.1 9.9 31.1 31.7c0 12.3-17 24.9-35.9 36.5c-8.2 5-24.7 14.5-27.5 34.2h96.1v-46.4h38.9c0 0 48.3 1.5 48.3 56c0 51.4-45 54.5-45 54.5h-42.2v-61.4'
        strokeWidth='17'
        stroke='#000'
        strokeMiterlimit='10'
        fill='none'
        strokeDashoffset='944.3'
        strokeDasharray='944.3 944.3'
      />
    </svg>
  </LocalizedLink>
);

const TopHeader = ({ menuItems, onSidebarOpen, location }) => {
  const classes = topHeaderUseStyles();
  const { y: scrollY } = useWindowScroll();

  return (
    <>
      <Desktop>
        <HeaderDesktopContainer scrollY={scrollY}>
          <LogoSvg />
          <Grid container className={classes.container}>
            {menuItems &&
              menuItems.map((item, index) => {
                return (
                  <Grid item xs={4} key={index} className={classes.item}>
                    <LocalizedLink
                      to={`/${item.link.uid}`}
                      className={`${classes.link}`}
                      activeClassName='active'
                    >
                      {item.link_title.text}
                    </LocalizedLink>
                  </Grid>
                );
              })}
            <Grid item xs={4} className={classes.item}>
              <LocaleSwitcher
                data-name='locale-switcher'
                className={`${classes.link} LocaleSwitcher`}
              >
                <LocaleComponent location={location} />
              </LocaleSwitcher>
            </Grid>
          </Grid>
          <HeaderImageDesktop className='Header__Image-desktop' />
        </HeaderDesktopContainer>
      </Desktop>

      <Mobile>
        <HeaderMobileContainer scrollY={scrollY}>
          <LogoSvg />
          <div className='Header__Flyout-btn'>
            <HeaderImageMobile className='Header__Image-mobile' />
            <PlusRed className='Header__PlusSVG' onClick={onSidebarOpen} />
          </div>
        </HeaderMobileContainer>
      </Mobile>
    </>
  );
};

export default TopHeader;
