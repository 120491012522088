import React from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';
import { FlyoutMenuContainer, LocaleSwitcher } from './Header.css';
import SocialMedia from '../SocialMedia/SocialMedia';
import Text from '../Text/Text';
import { Mobile } from '../Media/Media';
import { EscBtn } from '../Icons/EscBtn';
import { LogoWhite } from '../Icons/LogoWhite';
import { theme as localTheme } from '../../styles';
import LocalizedLink from '../LocalizedLink';
import LocaleComponent from './LocaleComponent';
import { flyoutMenuUseStyles } from './HeaderMaterialStyles.css';

let theme = createMuiTheme({});
theme = {
  ...theme,
  overrides: {
    MuiPaper: {
      root: {
        width: '100%',
      },
    },
    MuiList: {
      root: {
        maxWidth: '400px',
        marginBottom: '30px',
      },
      padding: {
        [theme.breakpoints.up('600')]: {
          paddingTop: '80px',
        },
      },
    },
    MuiDrawer: {
      paper: {
        border: '1px solid black',
        background: localTheme.colors.primaryred,
      },
    },
    MuiTypography: {
      body1: {
        textTransform: 'uppercase',
        fontSize: '37px',
        color: 'white',
        paddingBottom: '0',
        fontFamily: localTheme.fontFamily.secondary,
        lineHeight: '0px',
        letterSpacing: '0px',
        fontWeight: '500',
        [theme.breakpoints.up('600')]: {
          fontSize: '50px',
          lineHeight: '45px',
          letterSpacing: '0.04em',
        },
      },
      displayBlock: {
        display: 'inline-flex!important',
      },
    },
    MuiSvgIcon: {
      root: {
        width: '24px',
        height: '24px',
      },
    },
    MuiListItemText: {
      root: {
        border: `1px solid ${localTheme.colors.primaryred}`,
        textDecoration: 'none',
        '&:focus': {
          outline: 'none !important',
          border: '1px solid white',
          padding: '3px',
        },
      },
      primary: {
        '&:focus': {
          outline: 'none !important',
          padding: '0px',
          paddingLeft: '4px',
          paddingRight: '4px',
        },
      },
    },
    MuiTouchRipple: {
      root: {
        display: 'none',
      },
    },
    MuiListItem: {
      root: {
        paddingBottom: '0px !important',
        paddingTop: '0px!important',
        '&:hover': {
          backgroundColor: 'transparent !important',
        },
      },
      gutters: {
        paddingLeft: '0px',
        paddingRight: '0px',
      },
    },
  },
};

const FlyoutMenu = ({
  open,
  onOpen,
  onClose,
  menuItems,
  infoQuery,
  location,
}) => {
  const classes = flyoutMenuUseStyles();

  return (
    <ThemeProvider theme={theme}>
      <SwipeableDrawer open={open} onOpen={onOpen} onClose={onClose}>
        <div
          className={classes.sidebar}
          role='presentation'
          onKeyDown={onClose}
          onClick={onClose}
        >
          <FlyoutMenuContainer>
            <List className='Header__MenuList'>
              {menuItems &&
                menuItems.map((item, index) => {
                  return (
                    <MenuItem key={index}>
                      <LocalizedLink to={`/${item.link.uid}`}>
                        <ListItemText primary={item.link_title.text} />
                      </LocalizedLink>
                    </MenuItem>
                  );
                })}
              <LocaleSwitcher
                data-name='locale-switcher'
                className={`${classes.link} LocaleSwitcher LocaleSwitcherFlyout`}
              >
                <LocaleComponent location={location} />
              </LocaleSwitcher>
            </List>

            <div className='Flyout__Content'>
              <div className='Flyout__Contact-container '>
                <Mobile>
                  <div className='Flyout__Contact'>
                    {infoQuery[0].contact_email ? (
                      <Text customClassName='Flyout__text'>
                        <a
                          className='Footer__MailTo-button'
                          href={`mailto:${infoQuery[0].mail_to.text}`}
                        >
                          {infoQuery[0].contact_email.text}
                        </a>
                      </Text>
                    ) : (
                      ''
                    )}

                    {infoQuery[0].contact_tel ? (
                      <Text customClassName='Flyout__text'>
                        {infoQuery[0].contact_tel.text}
                      </Text>
                    ) : (
                      ''
                    )}
                  </div>
                </Mobile>

                <div className='Flyout__Contact'>
                  {infoQuery[0].contact_address ? (
                    <Text customClassName='Flyout__text'>
                      {infoQuery[0].contact_address.text}
                    </Text>
                  ) : (
                    ''
                  )}
                </div>

                <SocialMedia customClassName='Flyout__SocialMedia' />
              </div>

              {infoQuery[0].pdf_link && infoQuery[0].pdf_label ? (
                <div className='Flyout__Report-container'>
                  <a href={infoQuery[0].pdf_link.url}>
                    {infoQuery[0].pdf_label.text}
                  </a>
                </div>
              ) : (
                ''
              )}
            </div>
            <EscBtn className='Flyout__Button-esc' />
            <LocalizedLink to='/'>
              <LogoWhite className='Flyout__Logo' />
            </LocalizedLink>
          </FlyoutMenuContainer>
        </div>
      </SwipeableDrawer>
    </ThemeProvider>
  );
};

export default FlyoutMenu;
