import styled from "@emotion/styled";
import { theme } from "../../styles";

export const SocialMediaContainer = styled.div`
  display: flex;
  margin-top: 15px;

  @media (min-width: ${theme.breakpoints.m}) {
    justify-content: flex-end;
    margin-top: 0;
  }

  .SocialMedia__Content-Container {
    display: inline-flex;
    justify-content: center;
    flex-direction: row;
    margin-left: -15px;
    margin-bottom: 15px;

    @media (min-width: ${theme.breakpoints.m}) {
      margin-left: 0px;
      margin-right: -15px;
    }
  }

  .SocialMedia__Content-Line {
    margin: 15px 15px 0 0;

    @media (min-width: ${theme.breakpoints.m}) {
      margin-top: 1px;
    }
  }

  .SocialMedia__Content-Text {
    font-family: ${theme.fontFamily.secondary};
    font-weight: 100;
    text-transform: uppercase;
    padding: 15px;
    white-space: nowrap;
    margin: 0;
    transition: color 300ms;

    &:hover {
      color: ${theme.colors.primaryred};
    }

    @media (min-width: ${theme.breakpoints.m}) {
      padding-top: 0;
    }
  }
`;
