const website = require('./website');

module.exports = {
  'fr-ca': {
    default: false,
    path: 'fr',
    locale: 'fr-ca',
    siteLanguage: 'fr',
    ogLang: 'fr_CA',
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription:
      "Catalyseur de développement pour l'autonomisation des populations",
    headline: 'Catalyseur de développement',
    category: 'Categorie',
    categories: 'Categories',
    was: 'was',
    were: 'were',
    tagged: 'avec les mots-clés',
    recent: 'récent',
    projects: 'projets',
    allCategories: 'Tous les catégories',
    entries: 'entrées',
  },
  'en-gb': {
    default: true,
    path: 'en',
    locale: 'en-gb',
    siteLanguage: 'en',
    ogLang: 'en_GB',
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: website.description,
    headline: website.headline,
    category: 'Category',
    categories: 'Categories',
    was: 'was',
    were: 'were',
    tagged: 'tagged with',
    recent: 'Recent',
    projects: 'projects',
    allCategories: 'All categories',
    entries: 'entries',
  },
};
