module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'C2D - Catalyseur de développement', // Navigation and Site Title
  titleAlt: 'C2D', // Title for JSONLD
  description: 'C2D Services conceptualise et met en œuvre des projets de modernisation des institutions publiques et des organisations sur 4 continents depuis 2011.',
  headline: 'C2D - Catalyseur de développement', // Headline for schema.org JSONLD
  url: 'http://www.c2dservices.com/', // Domain of your site. No trailing slash!
  logo: '/logos/logo.png', // Used for SEO
  ogLanguage: 'fr_CA', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/favicon.png', // Used for manifest favicon generation
  shortName: 'C2D', // shortname for manifest. MUST be shorter than 12 characters
  author: 'FieldOffice', // Author for schemaORGJSONLD
  themeColor: '#000000',
  backgroundColor: '#ffffff',

  twitter: '@C2DServices', // Twitter Username
  facebook: 'C2DServices', // Facebook Site Name
  googleAnalyticsID: 'UA-155216455-3',

  skipNavId: 'reach-skip-nav', // ID for the "Skip to content" a11y feature
}
