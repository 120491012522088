import styled from '@emotion/styled';
import { theme } from '../../styles';

export const HeaderContainer = styled.div`
  width: 100%;
  padding: 0 10px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  background-color: white;
  max-width: 580px;
  border-bottom: 1px solid black;

  @media (min-width: ${theme.breakpoints.md}) {
    width: 100%;
    max-width: ${theme.scale('1440px')};
    padding: ${theme.scale('0 60px 0 60px')};
    border-bottom: initial;
  }

  .Header__Section-BreakLine {
    border-top: 1px solid black;
    margin-bottom: 50px;

    @media (min-width: ${theme.breakpoints.md}) {
      margin-bottom: ${theme.scale('50px')};
    }
  }
`;

export const HeaderMobileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => (props.scrollY > 40 ? '10px 0' : '15px 0')};
  transition: padding 350ms ease-out;
  will-change: padding;
  max-height: 100px;

  .Header__Image-mobile {
    height: auto;
    width: ${(props) => (props.scrollY > 40 ? '120px' : '162px')};
    transition: width 350ms ease-out;
    will-change: width;

    @media (max-width: ${theme.breakpoints.xs}) {
      max-width: 140px;
    }

    @media (max-width: ${theme.breakpoints.xxs}) {
      display: none;
    }
  }

  .Header__PlusSVG {
    height: height;
    margin-left: 20px;
    width: ${(props) => (props.scrollY > 40 ? '30px' : '48px')};
    transition: width 350ms ease-out;
    will-change: width;

    .cls-4 {
      fill: ${theme.colors.primaryred};
    }
  }

  .Header__Logo {
    cursor: pointer;
    padding: 5px 0;
    box-sizing: content-box;
    width: ${(props) =>
      props.scrollY > 40 ? '60px !important' : '89px !important'};
    transition: width 350ms ease-out;
    will-change: width;
  }

  .Header__Flyout-btn {
    display: flex;
    justify-content: flex-end;
  }
`;

export const HeaderDesktopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid black;
  padding: ${(props) =>
    props.scrollY > 40 ? '0 0 0' : theme.scale('40px 0 40px', 0.6)};
  transition: 350ms ease-out;
  transition-property: padding;
  will-change: padding;

  .Header__Image-desktop {
    height: auto;
    transition: width 350ms ease-out;
    will-change: width;
    width: ${(props) =>
      props.scrollY > 40 ? theme.scale('200px') : theme.scale('270px')};
  }

  a {
    transition: none;
  }

  .Header__Logo {
    cursor: pointer;
    padding: 17px 0;
    box-sizing: content-box;
    transition: width 350ms ease-out;
    will-change: width;
    margin-right: auto;
    width: ${(props) =>
      props.scrollY > 40 ? theme.scale('100px') : theme.scale('130px')};
  }
`;

export const FlyoutMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: end;
  position: relative;
  padding: 30px;
  margin-top: 30px;
  padding-top: 100px;
  height: 100%;
  min-height: 100vh;

  @media (max-width: ${theme.breakpoints.xxs}) {
    padding: 15px;
    padding-top: 100px;
  }

  @media (min-width: ${theme.breakpoints.s}) {
    padding-top: 200px;
    padding: 45px;
    margin-top: 45px;
  }

  .Flyout__Contact {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin-bottom: 30px;

    @media (min-width: ${theme.breakpoints.md}) {
      margin-right: 40px;
      white-space: pre-wrap;
      margin-bottom: 0px;
    }
  }

  .Flyout__Content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 70px;

    @media (min-width: ${theme.breakpoints.md}) {
      width: 70%;
    }
  }

  .Flyout__SocialMedia {
    color: white;
  }

  .Flyout__Contact-container {
    display: flex;
    max-width: 350px;
    flex-direction: column;

    @media (min-width: ${theme.breakpoints.md}) {
      width: 100%;
      flex-direction: row;
    }
  }

  .Flyout__text {
    font-family: ${theme.fontFamily.secondary};
    font-weight: 100;
    text-transform: uppercase;
    margin-bottom: 0px;
    color: white;
    line-height: 30px;

    font-size: ${theme.fonts.m};

    @media (min-width: ${theme.breakpoints.s}) {
      font-size: ${theme.fonts.xl_small};
      line-height: 34px;
    }

    a {
      color: white;
      font-weight: 400;
    }
  }

  .Flyout__SocialMedia {
    display: flex;
    justify-content: start;
  }

  .SocialMedia__Content-Text {
    font-weight: 100;
    text-transform: uppercase;
    margin-bottom: 0px;
    color: white;
    line-height: 40px;
    font-size: ${theme.fonts.m};
    @media (min-width: ${theme.breakpoints.s}) {
      font-size: ${theme.fonts.xl_small};
    }
  }

  .SocialMedia_Content-Symbol {
    font-size: ${theme.fonts.xl_small};
    margin-bottom: 0px;
    margin-top: 20px;
  }

  .Flyout__Report-container {
    position: absolute;
    background-color: white;
    font-family: ${theme.fontFamily.secondary};
    font-size: ${theme.fonts.m};
    font-weight: 600;
    top: 0px;
    left: 0;

    @media (min-width: ${theme.breakpoints.s}) {
      font-size: ${theme.fonts.xl_small};
    }

    a {
      margin-bottom: 0px;
      padding-right: 3px;
      padding-left: 3px;
      line-height: 30px;
      color: ${theme.colors.primaryred};
    }
  }

  .Flyout__Button-esc {
    width: 26px;
    height: 26px;
    position: absolute;
    top: 0;
    right: 25px;

    @media (min-width: ${theme.breakpoints.s}) {
      width: 38px;
      height: 38px;
      right: 50px;
    }

    @media (max-width: ${theme.breakpoints.xxs}) {
      right: 25px;
    }
  }

  .Flyout__Logo {
    width: 70px;
    height: 72px;
    position: absolute;
    top: -15px;

    @media (min-width: ${theme.breakpoints.s}) {
      width: 98px;
      height: 72px;
      top: 0;
    }
  }
`;

export const LocaleSwitcher = styled.div`
  padding: 1rem;
  width: 100px;

  &.LocaleSwitcherFlyout {
    padding-left: 0;

    .Header__LanguageSwitcher-button {
      color: white;
    }
  }

  .Header__LanguageSwitcher-button {
    color: ${theme.colors.primaryred};
  }

  @media (min-width: ${theme.breakpoints.md}) {

    &:hover {
      .Header__LanguageSwitcher-button {
        color: ${theme.colors.primaryred};
      }
    }
  }

  .Header__LanguageSwitcher-button {
    font-weight: 500;
    transition: none;
  }
`;
