import React from 'react';

export const PlusRed = ({ className, onClick }) => {
  return (
    <svg
      className={className ? className : ''}
      onClick={onClick ? onClick : () => {}}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 48.16 48.16'
    >
      <title>Asset 4</title>
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Layer_1-2' data-name='Layer 1'>
          <polygon
            fill='#f54634'
            points='48.16 21.65 26.51 21.65 26.51 0 21.65 0 21.65 21.65 0 21.65 0 26.51 21.65 26.51 21.65 48.16 26.51 48.16 26.51 26.51 48.16 26.51 48.16 21.65'
          />
        </g>
      </g>
    </svg>
  );
};
