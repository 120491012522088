/* eslint react/prop-types: 0, react/display-name: 0 */
import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ProjectsProvider } from './src/context/ProjectsProvider';
import { LocaleProvider } from './src/context/LocaleProvider';
import { Layout } from './src/components/Layout';
import globalStyle from './src/styles/global';
import { theme } from './src/styles';
import { Global } from '@emotion/core';
import { ThemeProvider } from 'emotion-theming';
import Header from './src/components/Header/HeaderMain';
import ClientOnly from './src/components/ClientOnly';

const wrapPageElement = ({ element, props }) => {
  return (
    <LocaleProvider location={props.location} locale={props.pageContext.locale}>
      <ProjectsProvider>
        <ParallaxProvider>
          <ThemeProvider theme={theme}>
            <Global styles={globalStyle} />
            <ClientOnly>
              <Header location={props.location} />
              <Layout {...props}>{element}</Layout>
            </ClientOnly>
          </ThemeProvider>
        </ParallaxProvider>
      </ProjectsProvider>
    </LocaleProvider>
  );
};

export default wrapPageElement;
