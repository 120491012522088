import styled from "@emotion/styled";
import { theme } from "../styles";

const Wrapper = styled.div`
  margin: 0 auto;
  overflow-wrap: anywhere;
  overflow: hidden;
  padding: 60px 10px 10px;
  max-width: ${theme.maxWidthMobile};

  @media (min-width: ${theme.breakpoints.md}) {
    padding: ${theme.scale('90px 60px 30px')};
    max-width: ${theme.scale(theme.maxWidthDesktop)};
  }
`;

export default Wrapper;
